import { useLocation, useRoutes } from 'react-router-dom'
import LoginRoutes from './LoginRoutes'
import { MainRoutes, routesNoMatch } from './MainRoutes'

export default function ThemeRoutes() {
  const location = useLocation()

  if (location.pathname !== '/' && location.pathname !== '/home') {
    localStorage.setItem('pathRequest', location.pathname)
  }

  if (localStorage) {
    localStorage.setItem('lastPath', localStorage.getItem('currentPath') || '')
    localStorage.setItem('currentPath', location.pathname)
  }

  return useRoutes([LoginRoutes, MainRoutes, routesNoMatch])
}
