import { useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import IconButton from 'components/@extended/IconButton'
import useConfig from 'hooks/useConfig'
import { ReactComponent as Moon } from 'assets/images/icons/moon.svg'
import { ReactComponent as Sun } from 'assets/images/icons/sun.svg'

const ThemeMode = () => {
  const theme = useTheme()
  const { onChangeMode } = useConfig()

  const anchorRef = useRef<any>(null)
  const handleToggle = () => onChangeMode(theme.palette.mode === 'dark' ? 'light' : 'dark')
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100'
  const Icon = theme.palette.mode === 'dark' ? <Sun fill={theme.palette.grey[900]} /> : <Moon fill={theme.palette.grey[900]} />

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: iconBackColor }}
        aria-label="Change theme mode"
        ref={anchorRef}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {Icon}
      </IconButton>
    </Box>
  )
}

export default ThemeMode
