import { FormattedMessage } from 'react-intl'
import { SolutionOutlined, CheckCircleOutlined, TeamOutlined, SettingOutlined, FileSearchOutlined } from '@ant-design/icons'
import { NavItemType } from 'types/menu'

const other: NavItemType = {
  id: 'other',
  title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      url: '/account/basic',
      icon: SolutionOutlined
    },
    {
      id: 'employee',
      title: <FormattedMessage id="employees" />,
      type: 'item',
      icon: TeamOutlined,
      url: '/employee/list'
    },
    {
      id: 'services-activities',
      title: <FormattedMessage id="services-activities" />,
      type: 'item',
      url: '/services-activities/list',
      icon: CheckCircleOutlined
    },
    {
      id: 'compliance-irc',
      title: <FormattedMessage id="compliance-irc" />,
      type: 'item',
      url: '/compliance-irc',
      icon: FileSearchOutlined
    },
    {
      id: 'configuration',
      title: <FormattedMessage id="configuration" />,
      type: 'item',
      url: '/configuration',
      icon: SettingOutlined
    }
  ]
}

export default other
