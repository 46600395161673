import other from './other'
import inicio from './inicio'
import aplicaciones from './aplicaciones'
import { NavItemType } from 'types/menu'

const menuItems: { items: NavItemType[] } = {
  items: [inicio, aplicaciones, other]
}

export default menuItems
