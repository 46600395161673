import { useContext } from 'react'

import { userContext } from 'contexts/UserContext'

const useAuth = () => {
  const context = useContext(userContext)

  if (!context) throw new Error('context must be use inside provider')

  return context
}

export default useAuth
