import { lazy } from 'react'
import GuestGuard from 'utils/route-guard/GuestGuard'
import CommonLayout from 'layout/CommonLayout'
import Loadable from 'components/Loadable'

const AuthLogin = Loadable(lazy(() => import('pages/auth/login')))

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: 'login',
          element: <AuthLogin />
        }
      ]
    }
  ]
}

export default LoginRoutes
