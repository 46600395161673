import Routes from 'routes'
import ThemeCustomization from 'themes'
import Locales from 'components/Locales'
import RTLLayout from 'components/RTLLayout'
import ScrollTop from 'components/ScrollTop'
import Snackbar from 'components/@extended/Snackbar'
import { UserProvider } from 'contexts/UserContext'

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <UserProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </UserProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  )
}

export default App
