import { NavItemType } from 'types/menu'
import { profilesAccepted, profilesAdministrative, viewsProtected, viewsProtectedAdministrative, viewsDisabled } from 'config'

export const protectedMenu = (menu: NavItemType, profile: any) => {
  let children = menu.children

  if (!profilesAdministrative.includes(profile)) {
    children = children?.filter((e) => {
      return !viewsProtectedAdministrative.some((item) => e.url?.includes(item))
    })
  }

  if (!profilesAdministrative.includes(profile)) {
    if (!profilesAccepted.includes(profile)) {
      children = children?.filter((e) => {
        return !viewsProtected.some((item) => e.url?.includes(item))
      })
    }
  }

  children = children?.filter((e) => {
    return !viewsDisabled.some((item) => e.url?.includes(item))
  })

  return {
    ...menu,
    children
  }
}
