import { styled } from '@mui/material/styles'
import AppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { drawerWidth } from 'config'
import useAuth from 'hooks/useAuth'

interface Props extends MuiAppBarProps {
  open?: boolean
}

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<Props>(({ theme, open }) => {
  const { user } = useAuth()
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(!open && {
      width: `calc(100% - ${user?.loading ? 0 : theme.spacing(7.5)})`
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${user?.loading ? 0 : drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }
})

export default AppBarStyled
