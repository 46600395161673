import { Box, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import useConfig from 'hooks/useConfig'
import { useMemo } from 'react'
import Search from './Search'
// import Message from './Message'
import Localization from './Localization'
import Profile from './Profile'
// import Notification from './Notification'
import MobileSection from './MobileSection'
import ThemeMode from './ThemeMode'

const HeaderContent = () => {
  const { i18n } = useConfig()

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n])

  return (
    <>
      {!matchesXs && <Search />}
      {!matchesXs && localization}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <Notification /> */}
      {/* <Message /> */}
      <ThemeMode />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  )
}

export default HeaderContent
