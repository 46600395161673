import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GuardProps } from 'types/auth'
import { useIsAuthenticated } from '@azure/msal-react'

const AuthGuard = ({ children }: GuardProps) => {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true })
    }
  }, [isAuthenticated, navigate])

  return children
}

export default AuthGuard
