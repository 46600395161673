import { FormattedMessage } from 'react-intl'
import { HomeOutlined } from '@ant-design/icons'
import { NavItemType } from 'types/menu'

const inicio: NavItemType = {
  id: 'home',
  title: <FormattedMessage id="home" />,
  type: 'group',
  children: [{ id: 'home', title: <FormattedMessage id="home" />, type: 'item', url: '/home', icon: HomeOutlined }]
}

export default inicio
