import { ReactNode, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Skeleton, Stack, Typography } from '@mui/material'
import Avatar from 'components/@extended/Avatar'
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'
import ProfileTab from './ProfileTab'
import SettingTab from './SettingTab'
import { useMsal } from '@azure/msal-react'
import useAuth from 'hooks/useAuth'

interface TabPanelProps {
  children?: ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

const Profile = () => {
  const theme = useTheme()
  const { instance } = useMsal()
  const { user } = useAuth()
  const iniciales = user?.nombre
    ?.split(' ')
    .map((n) => n[0])
    .join('')

  const handleLogout = () => {
    localStorage.setItem('pathRequest', '')
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
      onRedirectNavigate: () => {
        return false
      }
    })
  }

  const anchorRef = useRef<any>(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const [value] = useState(0)

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300'

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar src={user?.avatar} size="sm">
            {iniciales}
          </Avatar>
          {user?.nombre ? <Typography variant="subtitle1">{user?.nombre}</Typography> : <Skeleton variant="text" width="100px" />}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar src={user?.avatar} sx={{ width: 32, height: 32 }}>
                              {iniciales}
                            </Avatar>
                            <Stack>
                              <Typography variant="h6">{user?.nombre}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {user?.cargo}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Profile
