import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import config from 'config'
import { GuardProps } from 'types/auth'
import { useIsAuthenticated } from '@azure/msal-react'

const GuestGuard = ({ children }: GuardProps) => {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      const lastPath = localStorage.getItem('pathRequest')
      if (lastPath) {
        navigate(lastPath!, { replace: true })
        return
      }
      navigate(config.defaultPath, { replace: true })
    }
  }, [isAuthenticated, navigate])

  return children
}

export default GuestGuard
