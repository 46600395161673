import { Link as RouterLink } from 'react-router-dom'
import { Link, Stack, Typography } from '@mui/material'
import { useState } from 'react'

const Footer = () => {
  const [count, setCount] = useState<number>(0)

  const handleClick = () => {
    setCount((prevCount: any) => prevCount + 1)

    if (count === 10) {
      setCount(0)
      window.open('https://youtu.be/mCdA4bJAGGk', '_blank')
    }
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Typography variant="caption" align="left" onClick={() => handleClick()}>
        &copy; {new Date().getFullYear()} GT Planner
      </Typography>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
          Acerca de
        </Link>
        <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
          Privacidad
        </Link>
        <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
          Términos
        </Link>
      </Stack>
    </Stack>
  )
}
export default Footer
