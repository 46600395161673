/* eslint-disable no-unreachable */
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from 'config'
import React, { createContext, useEffect, useState } from 'react'
import { callMsGraph } from 'services/graph'
import { callProfileApi, callProfileImage } from 'services/profile'
import { AuthProps } from 'types/auth'
import { fetchLogs } from 'services/logs'
import { fetchAPI } from 'utils/fetchAPI'

const initialState: AuthProps = {
  user: {
    id: 0,
    email: '',
    avatar: '',
    nombre: '',
    rol: '',
    cargo: '',
    rut: '',
    area: '',
    ciudad: '',
    codigo_empleado: '',
    fecha_ingreso: '',
    jefe_directo: '',
    linea_de_negocio: '',
    socio_linea_de_negocio: '',
    telefono: '',
    estado: false,
    estado_carga_horas: false,
    loading: true,
    cantidad_proyectos: 0,
    horas_actualues: 0,
    id_cargo_proceso: 0
  }
}

export const userContext = createContext<AuthProps>(initialState)

export const UserProvider = ({ children }: { children: React.ReactElement }) => {
  const [user, setUser] = useState(initialState)
  const { instance, accounts, inProgress } = useMsal()
  const [apiData, setApiData] = useState(null)

  const handleLogin = () => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then(async (accessTokenResponse) => {
          const accessToken = accessTokenResponse.accessToken

          sessionStorage.setItem('webApiAccessToken', accessTokenResponse.idToken)

          const responseGraph = await callMsGraph(accessToken)
          setApiData(responseGraph)

          let image = ''
          const responseProfile = await callProfileImage(accessToken)
          if (responseProfile) {
            const imageObjectURL = URL.createObjectURL(responseProfile as Blob)
            image = imageObjectURL
          }

          sessionStorage.setItem('user-access', '')

          const responseApiProfile = await callProfileApi(accessTokenResponse.idToken, responseGraph.mail)
          if (responseApiProfile?.error) {
            sessionStorage.setItem('user-access', responseApiProfile.error)
            instance.logoutRedirect({
              postLogoutRedirectUri: '/',
              onRedirectNavigate: () => {
                return false
              }
            })
          }

          if (!responseApiProfile.perfil[0].estado) {
            sessionStorage.setItem('user-access', 'Cuenta desactivada.')
            instance.logoutRedirect({
              postLogoutRedirectUri: '/',
              onRedirectNavigate: () => {
                return false
              }
            })
          }

          setUser({
            user: {
              id: responseApiProfile.perfil[0].id,
              email: responseGraph.mail,
              rol: responseApiProfile.perfil[0].rol,
              avatar: image,
              nombre: responseGraph.displayName,
              cargo: responseGraph.jobTitle,
              rut: responseApiProfile.perfil[0].rut,
              area: responseApiProfile.perfil[0].area,
              ciudad: responseApiProfile.perfil[0].ciudad,
              codigo_empleado: responseApiProfile.perfil[0].codigo_empleado,
              fecha_ingreso: responseApiProfile.perfil[0].fecha_ingreso,
              jefe_directo: responseApiProfile.perfil[0].jefe_directo,
              linea_de_negocio: responseApiProfile.perfil[0].linea_de_negocio,
              socio_linea_de_negocio: responseApiProfile.perfil[0].socio_linea_de_negocio,
              telefono: responseApiProfile.perfil[0].telefono,
              estado: responseApiProfile.perfil[0].estado,
              estado_carga_horas: responseApiProfile.perfil[0].estado_carga_horas,
              loading: false,
              cantidad_proyectos: responseApiProfile.perfil[0].cantidad_proyectos,
              horas_actualues: responseApiProfile.perfil[0].horas_actualues,
              id_cargo_proceso: responseApiProfile.perfil[0].id_cargo_proceso
            }
          })

          await fetchLogs(responseApiProfile.perfil[0].id, 'Login', 'Ingreso', 'Usuario ingresa a la aplicación', 'INFO')

          if (responseGraph.displayName !== responseApiProfile.perfil[0].nombre_completo) {
            const data = {
              nombre: responseGraph.givenName,
              apellido: responseGraph.surname
            }

            fetchAPI(`perfil?email=${responseGraph.mail}`, 'PUT', data)
              .then((response) => response.json())
              .then((response) => {
                if (response.error) {
                  console.log(response.error)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            const accessTokenResponse: any = instance.acquireTokenRedirect({ ...loginRequest, account: accounts[0] })
            sessionStorage.setItem('webApiAccessToken', accessTokenResponse?.idToken)
          }
          console.log(error)
        })
    }
  }

  useEffect(() => {
    handleLogin()
  })

  return (
    <>
      <userContext.Provider value={user}>{children}</userContext.Provider>
    </>
  )
}
