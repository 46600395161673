import { Link } from 'react-router-dom'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import config from 'config'
import error401 from 'assets/images/maintenance/Error401.svg'

function Error401() {
  return (
    <>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item xs={12}>
          <Box sx={{ width: { xs: 300, sm: 480 } }}>
            <img src={error401} alt="mantis" style={{ width: '100%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography align="center" variant="h1">
              No autorizado
            </Typography>
            <Typography color="textSecondary" align="center" sx={{ width: '85%' }}>
              No tienes permisos para acceder a esta página.
            </Typography>
            <Button component={Link} to={config.defaultPath} variant="contained">
              Volver al Home
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default Error401
