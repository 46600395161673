import { CustomTheme } from './theme'

import { PaletteThemeProps } from 'types/theme'
import { PalettesProps } from '@ant-design/colors'
import { ThemeMode, PresetColor } from 'types/config'

const Theme = (colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
  return CustomTheme(colors, mode)
}

export default Theme
