import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box, Container, Toolbar, Grid } from '@mui/material'
import Drawer from './Drawer'
import Header from './Header'
import Footer from './Footer'
import navigation from 'menu-items'
import useConfig from 'hooks/useConfig'
import Breadcrumbs from 'components/@extended/Breadcrumbs'
import { RootStateProps } from 'types/root'
import { openDrawer } from 'store/reducers/menu'
import useAuth from 'hooks/useAuth'
import CircularProgress from '@mui/material/CircularProgress'

const MainLayout = () => {
  const theme = useTheme()
  const { user } = useAuth()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'))

  const { container, miniDrawer } = useConfig()
  const dispatch = useDispatch()

  const menu = useSelector((state: RootStateProps) => state.menu)
  const { drawerOpen } = menu

  const [open, setOpen] = useState(!miniDrawer || drawerOpen)
  const handleDrawerToggle = () => {
    setOpen(!open)
    dispatch(openDrawer({ drawerOpen: !open }))
  }

  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG)
      dispatch(openDrawer({ drawerOpen: !matchDownLG }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG])

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen])

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {user?.loading ? (
        <Box sx={{ width: '100vw' }}>
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 1, alignItems: 'center' }}>
              <CircularProgress />
              <Box sx={{ mt: 2 }}>Obteniendo datos desde la API ...</Box>
            </Box>
          </Grid>
        </Box>
      ) : (
        <>
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            <Toolbar />
            {container && (
              <Container
                maxWidth="lg"
                sx={{
                  px: { xs: 0, sm: 2 },
                  position: 'relative',
                  minHeight: 'calc(100vh - 110px)',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                <Outlet />
                <Footer />
              </Container>
            )}
            {!container && (
              <Box
                sx={{
                  position: 'relative',
                  minHeight: 'calc(100vh - 110px)',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                <Outlet />
                <Footer />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default MainLayout
