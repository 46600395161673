import useAuth from 'hooks/useAuth'
import MaintenanceUnauthorized from 'pages/maintenance/401'

export const ProtectedRoute = ({ children, roles = ['Nivel 6'] }: any) => {
  const { user } = useAuth()

  if (roles.includes(user?.rol)) {
    return <>{children}</>
  }

  return <MaintenanceUnauthorized />
}
