import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from 'config'
import { store } from 'store'
import { ConfigProvider } from 'contexts/ConfigContext'

import 'simplebar/src/simplebar.css'
import 'assets/third-party/apex-chart.css'
import 'assets/third-party/react-table.css'

const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>,
  document.getElementById('root')
)

reportWebVitals()
