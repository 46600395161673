import { apiConfig } from 'config'

export const fetchAPI = (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body: any = {}, signal?: AbortSignal, external?: boolean) => {
  type OptionsProps = {
    method: string
    headers: Headers
    body?: string
    signal?: AbortSignal
  }
  const accessToken = sessionStorage.getItem('webApiAccessToken')
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  let options: OptionsProps = {
    method: method,
    headers: headers,
    signal: signal
  }

  if (['POST', 'PUT', 'DELETE'].includes(method)) {
    options.body = JSON.stringify(body)
  }

  if (external) {
    return fetch(url, options)
  }

  return fetch(`${apiConfig.urlBase}${url}`, options)
}
