import { apiConfig } from 'config'

export async function fetchLogs(id: number = 0, vista: string, accion: string, descripcion: string, tipo: string): Promise<any> {
  const accessToken = sessionStorage.getItem('webApiAccessToken')
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  const body = {
    id,
    vista,
    accion,
    descripcion,
    tipo
  }

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  }

  const URL = `${apiConfig.urlBase}registro/`

  return fetch(URL, options)
    .then((res) => res.json())
    .catch((err) => console.log(err))
}
