const LogoIcon = () => {
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36">
      <title>Nuevo proyecto</title>
      <defs>
        <image
          width="1843"
          height="354"
          id="img1"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABzMAAAFiAQMAAABI8WcMAAAAAXNSR0IB2cksfwAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAABmSURBVHic7cExAQAAAMKg9U9tCU+gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOBvQN8AAaG8tzcAAAAASUVORK5CYII="
        />
        <image
          width="25"
          height="26"
          id="img2"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAaCAMAAAB1owf/AAAAAXNSR0IB2cksfwAAAo5QTFRFAAAAkHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kX25kX26kn66kn66k3+7lIC8kHy5kHy5kHy5kHy5kX65k3+7lIG8lIG8lYK8lYK9lYO9loO9l4S+mIa+mYe/lYO7l4W+mYe/m4nAm4nAnIrBnIvBnIvCnYzCno3CnYvCnIvBnY3Cno3CnYzCnIzCnIvCm4nAmYjAmonBmojAmYe/mIa/mIa+l4W+loS+lIG8mIa+l4S9loO9lIG8kn67k4C8kn+7kn67kX67kX66kH26kHy6j3u5jXm4jnq5jXi4jXi3jHi3jHe3i3a3i3a2inW2iXO1i3a2iXS1iXS1iHO1iHK0h3K0h3G0hnCzhW+zhW+zhW6zhG6zhG6yhG2yg22yg2yygmyxgWqwgWqwgGmwgGmvgGivf2ivf2evfmeufGWufWWufGWtfGSte2Ote2OsemKseF+qeF+qeF+qd1+qd16qdl2qdl2pdlypdVypclinc1mnclinclencVemcVamcFWmcFWlb1WlbFGjbVGkbFGjbFCja0+ja0+iak6iak6iZUmeZkmfZkmfZUifZUieZEeeY0adY0adY0aeX0GbYEGbX0GbX0CbXkCaXj+aXT+aXT6ZXT6ZXkCbWTqXWTqXWTmXWDiXWDiWVziWVzeWVjaWVzeWUjGSVDOTUzOTUzKTUjGTUTCSUTCSUjOTTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RVTGSTy6RTy6RTy6RTy6RTy6RTy6RTy6RTiyTUC6QTy6RTy6R63YHYAAAANp0Uk5TABlKhsHn/f/+6cmVWiQBDDVYZ3Oa1v///+6rPQcFBB9gvfz//////+l6CwA4yf7/////4lMBEJP////3bwKB+/////x8AQ2r/vY5EMn//////9UYPP3//////7sMAcX//////Vd0/////////6I3+///////0R7q/////+QS3v///////9sS3v///////7wc6P//////izb7///////6PnD/////////xgrD////////9jcw/f////+eAwq6/9sUEq3dLBO4/tUpJLb8sy0AFW3x+9+jMwABLi8DlJNVAAABXElEQVR4nGNgYGBkYmZhZWPn4OTi5uHlY4ADfgFBIWERUXZ2djExcQlJKWm4jIwskJCTV1BUUlZRVVNX19DUYkAF2jq6evoGBgaGRsYmpmhyZuYWFpZAYGVtY4smxWBn7+Dg4Ojo5Oziiibj5u6hrqaqoqzs6YWuydvH189fPCAgMCgYXSokNCw8IiIiMio6Bk0mNi4+ISEhMSkpOQVdU2paekZmVnZ2Ti66TF5+QUFhUXFxSSm6TFl5RUVlZVV1dQ26TG1dfUNjY1NzSyu6TFt7R0dnV3dPbx+6TP+EiRMnTZ4ydeo0dJnpM2bOmj1nztx589FlFixctHjJ0mXLV6xEl1m1es3ades3bNyELrF5y9Zt27Zv37FzF5rE7j17wWDffjSJAwchEocOo0kcOXoMLHH8BJrEyVNg8dNnzqIInzt/4SJQ+NLlK1evoem4fv7G1atXb+6/DhMAAFvzf198Qi8uAAAAAElFTkSuQmCC"
        />
        <image
          width="26"
          height="25"
          id="img3"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAMAAAAYAM5SAAAAAXNSR0IB2cksfwAAAm1QTFRFAAAAkHy4kXy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kX25kHy5kHy5TC+Okn+6kX25Ty6RlIC7kn66Ty6QTy6RmYe/lYK8Ty6RTy6RTy6Rno3CmonAloO9Ty6RTy6RTy6RnY3CnIvBloS9kHy5Ty6RTy6RTy6RTy6RUSiUmojAnYzCnIvBl4S+kn+7kHy5Ty6RTy6Rkn+7lYO9mYe/nIzCl4W+k3+7kHy5Ty6RTy6RUTCRUjOZjXm4kX26lYK8mIe/nYzCk4C7kX25Ty6STy6RUjGTWDmWXj+aY0adak6hcValeF+qe2OsgGmvhG6yiHO1jHe3kH26lIG8mIa+lIC7kX25Ty6RWDmXXkCaZEeea0+icVemd1+qe2StgGivhG6yiHK0i3e3kHy6mIa/lYK8Ty6RUTCSWDiWXj+aZEaeak+icVemd16qe2Otf2ivh3K0i3a3mIW+no3CmYe/loO9Ty6RTy6RVzeWXT+aY0adak6icVame2Osf2evg22yi3a2j3y5k4C8m4rBnY3Cm4jATy6RTy6RUS+SXT6ZY0WdcFWmd12qh3G0j3u5m4rBnY3CTy6RTy6RUC+RVjaWYkWdaU2hcFWldl2pemKsfmeug22xinW2k4C7mojAnIvBTy6RVjaVXD2ZYkSdaUyhb1Sldlypfmaug2yxhnG0kn+7loO9mIa/UC+SVjWVYUScaEyhdVypgmyxhnCzjnq5kX66lIK8SSSSVjeVWzyYYUOcaEugblOkdVupeWGsfWauiXS1jHi3jXu5XT+aYUOcZ0ygblOkdVuoeWGrfWWugWuwhW+zh3K09bqaqAAAAM90Uk5TAAIRLQc5OjSEQ8Vn8Qij/wA38BiQ/wWDFu4v5E4Gpv/z1xls+v/3WPj/pAA6+v///86iFwhz+////5Dj1U8AKbX8///8RgS9/86ARCMWFR0ybr77////zwx+///+38XD0/X/////+38e/////////////////8ETiP7////////////////2MBTN//////////13Q/f//////////////7oQN/////////////62Dqz////////5hQgAVeH////////yRgAIUqnLzMzMx4wlMNrIGAAAAWFJREFUeJxjYMAOGJlwSAABMwtOKVY2nFLsHDilOLlwSnHz4JLh5eNnYBDAKiUoBJQSxiolIsrPICaOTUZCEiglJS2DKSMrJw+UklFQVMKQUlZRFVVjUNfQ1NJGk9HR1dM3MATq0tQ0MkaWMDE1Mze3sLRikLLW1NS0sbWDy9g7ODqZO1u4uDK4uQOlND08vbx9fP38AwKDgkNCw/Scw8IjGBgiwVJR0TGxcfEJiUnJKakgqbR0oAEZGkCpzKzsnNy8/ILCpKLi1NASvdKycpDhFZVAqarqmtq6/PqGxqKm5haL1rZ2sL0dnZqaXVXdPbW9fUCp/qYJLRYTJ0HdNHmK5tRp3dNnzJw1e87c/nkT5usvWAhz7yLFqYuXLF22fMXslatWz5uwZu06hC8z1m9YsnHT8s2zV27ZOm/b9h3IIbBz1+49e/ftP3Bwy9ZDh4+ghdvRY8dPnDx1+szZczARAEW8amS9p1KbAAAAAElFTkSuQmCC"
        />
        <image
          width="26"
          height="27"
          id="img4"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAbCAMAAABVyG9ZAAAAAXNSR0IB2cksfwAAAppQTFRFAAAATC2TTy6RTy6RTy6RTy6RTy6RTy6RTy2RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy+RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTy6RTi+RTy6RTy6RTy6RTy6RTy6RUC+RUC+RUC+SUTCSUS+STy6RVjaVVDSUVDSUVTSUVTWVVjaVVzeWVTWUWzyYWzyYWzyZXD2ZXT6ZXT6aXj+aXj+aYkWdYUOcYUOcYkScYkWdY0WdY0adY0aeY0adZ0qgZkqfZ0qgZ0ugaEugaEyhaUyhaU2haU2hbVKjbVKkblKkblOkb1Olb1Slb1WlcFWmcFamcFamc1modFqodFuodVupdVypdl2pdl6qd16qeWCreWCseWGsemGsemKse2Kse2Kte2Ote2OtfGStfWWufWaufmaufmeufmevf2evf2ivf2ivgGmwgWqwgWuwgmuxgmyxg2yxg22xhG6yhW+zhnCzhnG0h3G0h3K0iHO1iXS1hnC0iHO1inS2inW2i3a2i3a3i3e3jHe3ina2jXm4jXm4jnm4jnq4j3q5j3u5kHy5kX26kH26kX67kn+7k4C7k4C8k4G8lIG8lIK8loO+k4C7lYK9loS9l4S+l4W+mIW+mIa/mIa/mYfAmonAmYCzmYjAm4nBm4rBnIrBnIvCnYvCnYzCno3CnYzCnY3Cno3Cno3DnY3CnYzCnYzCnIvBmojAm4PBnYzCnIvBm4nAmoi/mYe/mYa/mIa+l4S+lYK8lIG7lYK9lIG8k4C7k3+7kn66kX25kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kX25kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy6J+nK5AAAAN50Uk5TAAARJC4tIg8CMH274fTz3a08AjKk//23AgR89/JcAYlSAV+GUv///8YICd3//////zJy////////0AfL////////kyr0////////Z2H//////////0mI////////QpP/////////UIf/////////cWL//////6sj7v/////0EQKj//////9yJPP//////+8lXvL//////8MQBFv2//////6jAwBg//////+hBmru//////6+NwABNan/////8ZQlQLPz///9x4hZQDw6HgIDJ2mt6f/71ZNRGQsoMzEDahNlQgAAAWRJREFUeJxjYIABRiZmFlY2dgYMwMHJxc3Dy8cvICiEJiMswisKBGLighKoEpJS0iAJURlZOTQtkvJiYBk+BXRrFJUgMqLKGC5QkVFVVVVTV9fQRJfR0tbR1dPXNzAwxNBkZGxiampmbmGJIWNlbWNja2fv4OiEIeXs4urm7uHp5eWNIeXj6+cfEBgUHBKKIRUWHh4RGRUdHROLIRUXn5CYmJSckpqGIZWekZmZlZ2Tm5ePIVVQWFhUXFxSWlqGIVVeUVlZVV1dU1Nbhy5V39DY1NTU3NzS2oahrb2js6u7u6e3t68fQ27CxEmTp0yZOm3a9BnoUjNnzZ4zZ+7cefMXLFyELrd4ydKly5YtX7Fi5arVGGauWbtu3fp1GzZu2rxlK5rUtu07du7atXvPvHl79+3H0Hjg4KHDk44AwdFjx0+cPHX6zFmE3LnzFy5eugwCV65eu34DTefNW7dB4M6ZuyAeAOaafGlvXIBdAAAAAElFTkSuQmCC"
        />
        <image
          width="26"
          height="25"
          id="img5"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAMAAAAYAM5SAAAAAXNSR0IB2cksfwAAAqNQTFRFAAAAiXG0hW+ygmyxfmeuemKsdVypb1Wlak6iZEedYUOejXi4inW2hnG0g2yyf2eve2OsdlypcFWlak6iY0adXT6aVziWk4K8kn+7j3u5i3a2h3K0g22yf2ive2Otdl2pcFamak6iY0aeXT6aVzeWUjGSTy6RlYO9lIG8kHy6i3a3hG2ydl2qcVama0+iZEeeXT+aVzeWUTCSTy6RTy6RmonAl4W+lIG8kH26jHe3iHK0gGivfGStd16qcVema0+jXj+aVziWUjGTTy6RTy6RTy6Rno3CnIvBmIa+iHO1hG6ygGmvclenbFCjZUieXkCaWDiWTy6RTy6Rl4a+nYzCnIzCmIa/lYK9kX26jHi3hG6zgGmwfGWtd1+qclinbFGjZUifX0CbWDmXUzKTTy6RmYa/nIvCnYzCkX66jXi3iXO1hW6zgWmwfWWteF+qbVGkZkmfX0GbWTmXTy6RTy6RlIC7l4S+nIvBmYe/lYO9kX67iXS1hW+zgWqwfWWueF+rc1mnbVKkYEKbWTqXUzOTTy6RTCqQk3+7loO9nIrBloO9kn67jXi4iXS1hW+zgWqwfWWueF+rc1mnbVKkZkmfYEGbWjqXVDOUTy6RTy6RkHy5kn66kn+7jnm4inW2WDiWUzOTTy6RTy6RTy6RkHy5kn66m4nBno3CmojAl4S9k4C7iHe7UjCSTy6RTy6RTy6RkHy5m4nAmonAmIa+lYG9Ty6RTy6RkHy5kX25lYK8nY3Cm4rBTy6RTy6RkHy5mojAnYzCTy6RkHy5kHy5mIa+Ty6RTy6RkHy5k4C7loS9Ty6RTy6RkHy5kn66Ti6RkHy5kHy5kHy5kHy5kHy5kHy5kHy5kHy5j3u6kHy5kHy5kHy5kHy5kH25kHy5kHy5kHy5kHy5viE0SwAAAOF0Uk5TAAEcUYOWkXJCEgAFfub///////3bjhsBKsj/////////////+48TReH////////////+wxAw6P////////////////+2DyPd////////////rxcCuv///////////////////5lf//////////////////4qGcz//////////////////40Agvz/////+b19Uz4+UH27+//YDhPd8YgaFHjl+Cw+/P///+RCAAAo4DN5/8IxAC7esP//zQ0v1NXqLUPw/3SiI/bqDTII9IcD4+wuw68Ln4kAW3IiYgFBAxcI2XPgLgAAAW1JREFUeJxjYIACRiZmFlY2dg5OLgY0wM3Dy8cvICgkLCIqhiIhLiEpJS0jKyevoKikrKKqhiSlrqGpJa0tK6ejq6dvYGhkbAKXMTUzt7C00ra2sbWz13dwdHJ2cYXKuLl7AKU8vbxtbH18/fwDnJydA4PAMsEhoWHhEZGeUdExsXHxCYlJyc7OzilgqdS09LDwjMys7JzcvLj8gsIikFRxCVCmtKw8vaKyKrO6prauvqGxoKm5BSjl3NrGwNDe0Zle0dXd09vXP2HipMlTpk6bDpKaMZNh1uwukNScufNg7p2/YCFQatFihiVLK5ctX7Fy1WqEL9esXQeUW8+wISJ84/JNm7eghM7WbSCp7Tt2bty1Gz1I9+wFSu3bYb7/ALoMA8PB4vUMhw6bH8GUYWA4eozh+OETJ7FJnTrNcObwWWwyDOcYGM5fuIhVCgguXb6CS+rqteu4pG7cxCXDcOs2Tqk7d+/hlLv/AIcEANbOeQ0URAViAAAAAElFTkSuQmCC"
        />
      </defs>
      <style></style>
      <use id="Background" href="#img1" x="-904" y="-159" />
      <use id="Capa 1" href="#img2" x="9" y="0" />
      <use id="Capa 2" href="#img3" x="9" y="9" />
      <use id="Capa 3" href="#img4" x="1" y="9" />
      <use id="Capa 4" href="#img5" x="0" y="1" />
    </svg>
  )
}

export default LogoIcon
