import { useTheme } from '@mui/material/styles'

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme()
  return (
    <>
      <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 31" width="150" height="31">
        <title>Nuevo proyecto</title>
        <defs>
          <image
            width="1280"
            height="720"
            id="img1"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABQAAAALQAQMAAAD1s08VAAAAAXNSR0IB2cksfwAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAACHSURBVHic7cExAQAAAMKg9U9tCU+gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHgaxN8AAQfAspoAAAAASUVORK5CYII="
          />
          <linearGradient id="g1" x1="20.1" y1="21.8" x2="2.1" y2="23" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#4f2e8d" />
            <stop offset=".1" stopColor="#4f2e8d" />
            <stop offset=".9" stopColor="#a495c2" />
            <stop offset="1" stopColor="#9784b9" />
          </linearGradient>
          <linearGradient id="g2" x1="21.8" y1="10.3" x2="23" y2="28.2" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#4f2e8d" />
            <stop offset=".1" stopColor="#4f2e8d" />
            <stop offset=".9" stopColor="#a495c2" />
            <stop offset="1" stopColor="#9784b9" />
          </linearGradient>
          <linearGradient id="g3" x1="10.3" y1="8.6" x2="28.2" y2="7.3" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#4f2e8d" />
            <stop offset=".1" stopColor="#4f2e8d" />
            <stop offset=".9" stopColor="#a495c2" />
            <stop offset="1" stopColor="#9784b9" />
          </linearGradient>
          <linearGradient id="g4" x1="8.6" y1="20.1" x2="7.3" y2="2.1" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#4f2e8d" />
            <stop offset=".1" stopColor="#4f2e8d" />
            <stop offset=".9" stopColor="#a495c2" />
            <stop offset="1" stopColor="#9784b9" />
          </linearGradient>
        </defs>
        <use id="Background" href="#img1" x="-565" y="-345" />
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              className="s0"
              d="m55.6 15.4v1.8h-1.7q-0.5 2.8-2.4 4.5-1.9 1.6-5 1.6-3.6 0-5.8-2.3-2.2-2.3-2.1-5.7 0-3.5 2.1-5.9 2.1-2.4 5.9-2.4 2.7 0 4.6 1.4 1.8 1.4 2.1 3.4h-2.2q-0.3-1.3-1.5-2.1-1.2-0.8-3-0.8-2.8 0-4.4 1.8-1.5 1.8-1.5 4.6 0 2.6 1.6 4.4 1.5 1.8 4.2 1.8 2.2 0 3.6-1.1 1.3-1.1 1.7-3.2h-3.8v-1.8z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              className="s0"
              d="m59.4 23v-13.7h-4.6v-1.9h11.3v1.9h-4.6v13.7z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              fillRule="evenodd"
              className="s0"
              d="m79.2 7.4q2.3 0 3.8 1.5 1.4 1.5 1.4 3.9 0 2.3-1.4 3.8-1.5 1.5-3.8 1.5h-3.5v4.9h-2.1v-15.6zm-0.1 8.9q1.5 0 2.4-1 0.9-1 0.9-2.5 0-1.6-0.9-2.6-0.9-1-2.4-1h-3.4v7.1z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              className="s0"
              d="m86.4 7.4h2v15.6h-2z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              fillRule="evenodd"
              className="s0"
              d="m95.9 23.2q-2.3 0-3.7-1.5-1.3-1.5-1.3-3.9 0-2.3 1.4-3.8 1.3-1.6 3.7-1.6 1.2 0 2.1 0.6 1 0.6 1.5 1.5v-1.8h1.9v10.3h-1.9v-1.8q-0.5 0.9-1.5 1.5-1 0.5-2.2 0.5zm0.3-1.7q1.6 0 2.5-1.1 1-1 1-2.6 0-1.5-1-2.6-0.9-1-2.5-1-1.6 0-2.5 1-0.9 1-0.9 2.6 0 1.6 0.9 2.7 0.9 1 2.5 1z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              className="s0"
              d="m104.8 23v-10.3h2v1.9q0.4-1 1.3-1.6 0.9-0.6 2.2-0.6 3.9 0 3.9 4.2v6.4h-2v-5.5q0-1.7-0.6-2.6-0.6-0.8-2-0.8-1.4 0-2.1 0.9-0.7 0.9-0.7 2.5v5.5z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              className="s0"
              d="m117.4 23v-10.3h1.9v1.9q0.4-1 1.4-1.6 0.9-0.6 2.2-0.6 3.8 0 3.8 4.2v6.4h-1.9v-5.5q0-1.7-0.6-2.6-0.6-0.8-2-0.8-1.4 0-2.1 0.9-0.8 0.9-0.8 2.5v5.5z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              fillRule="evenodd"
              className="s0"
              d="m139 17.2q0 0.5 0 1.2h-8q0 1.5 0.9 2.3 0.9 0.8 2.3 0.8 2.1 0 2.8-1.5h1.9q-0.3 1.4-1.6 2.3-1.3 0.9-3.1 0.9-2.3 0-3.7-1.4-1.4-1.5-1.4-4 0-2.4 1.4-3.9 1.4-1.5 3.7-1.5 2.1 0 3.5 1.4 1.3 1.3 1.3 3.4zm-8-0.3h6.1q0-1.3-0.8-2.1-0.8-0.8-2.2-0.8-1.3 0-2.2 0.8-0.8 0.8-0.9 2.1z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
              className="s0"
              d="m141.5 23v-10.3h1.9v2.3q0.3-1.2 1.2-1.8 0.8-0.6 1.8-0.6 0.6 0 1 0v1.8q-0.4 0-1 0-1.4 0-2.2 1.1-0.8 1.2-0.8 3v4.5z"
            />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  fill="url(#g1)"
                  className="s1"
                  d="m2 22.6c-4.1-7.1-1.7-16.2 5.4-20.5q0.2-0.1 0.3-0.1c5.5-2.9 16.4 3.5 13.3 13.2q0 0.2-0.1 0.3c0.1-0.3 0-0.8-0.2-1.1-1.2-2.5-3.1-4.3-5.5-5.1-9.7-3.1-16.1 7.8-13.2 13.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  fill="url(#g2)"
                  className="s2"
                  d="m22.6 28.4c-7.1 4-16.2 1.6-20.5-5.5q-0.1-0.1-0.1-0.3c-2.9-5.5 3.5-16.4 13.2-13.3q0.2 0.1 0.3 0.1c-0.3-0.1-0.8 0-1.1 0.2-2.5 1.3-4.3 3.2-5.1 5.6-3.1 9.6 7.8 16.1 13.3 13.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  fill="url(#g3)"
                  className="s3"
                  d="m28.4 7.7c4 7.2 1.6 16.3-5.5 20.5q-0.1 0.1-0.3 0.2c-5.5 2.9-16.3-3.6-13.3-13.2q0.1-0.2 0.1-0.4c-0.1 0.3 0 0.8 0.2 1.2 1.3 2.5 3.2 4.3 5.6 5 9.6 3.1 16.1-7.8 13.2-13.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  fill="url(#g4)"
                  className="s4"
                  d="m7.7 2c7.2-4.1 16.3-1.7 20.5 5.4q0.1 0.2 0.2 0.3c2.9 5.5-3.6 16.4-13.2 13.3q-0.2 0-0.4-0.1c0.3 0.1 0.8 0 1.2-0.2 2.5-1.2 4.3-3.1 5-5.5 3.1-9.7-7.8-16.1-13.3-13.2z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default LogoMain
